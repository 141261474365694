import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import BlockContent from '@sanity/block-content-to-react';
import { SinglePageEmbeddedVideo } from '../../components/SinglePageEmbedded';
import { Logo } from '../../components/Logo';
import { Share } from '../../components/Share';
import { MailTo } from '../../components/MailTo';
import { SEO } from '../../components/SEO';

export default function Studio({ data }) {
  const {
    title,
    _rawDescription,
    presenters,
    script,
    showrunner,
    director,
    video,
    slug,
  } = data.studio;
  return (
    <>
      <SEO title={title} description={slug.current} />
      <Logo theme="black" />
      <Wrapper>
        <h1> {title} </h1>
        <section>
          <div>
            <BlockContent blocks={_rawDescription} />
            <MailTo mailTo="mailto:reach@rumblewood.com" />
            <Share
              url={`https://rumblewood.netlify.app/commercial/${slug.current}&t=${title}`}
            />
          </div>
          <ProjectInfoContainer>
            <div>
              <TextBold> Showrunner: </TextBold>
              <span> {showrunner} </span>
            </div>
            <div>
              <TextBold> Script: </TextBold>
              <span> {script} </span>
            </div>
            <div>
              <TextBold> Director: </TextBold>
              <span> {director} </span>
            </div>
            <div>
              <TextBold> Presenters: </TextBold>
              <span> {presenters} </span>
            </div>
          </ProjectInfoContainer>

          <SinglePageEmbeddedVideo
            videoSrcURL={video}
            videoTitle={`Movie of - ${title}`}
            gallery
          />
        </section>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  margin-top: 80px;
  padding: 25px;
  background-color: var(--background);
  display: flex;
  flex-wrap: wrap;

  section {
    width: 100%;
    display: flex;
    justify-self: space-between;
    flex-wrap: wrap;
  }

  h1 {
    max-width: 500px;
    width: 33%;
    font-size: 40px;
    line-height: 45px;
    margin: 0px 25px 25px 0px;
    letter-spacing: 0.04px;
    color: var(--black);
    font-stretch: normal;

    @media screen and (max-width: 800px) {
      margin-top: 0px;
      width: 100%;
    }
  }

  p {
    max-width: 500px;
    min-width: 250px;
    font-size: 15px;
    line-height: 21px;
    font-weight: normal;
    color: var(--black);
    letter-spacing: 0.2px;
    display: block;
    margin: 0;
    flex: 3;
    margin-bottom: 25px;
    @media screen and (max-width: 800px) {
      min-width: 100%;
    }
  }
`;

const ProjectInfoContainer = styled.div`
  max-width: 250px;
  max-height: 250px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  padding: 0 40px;

  @media screen and (max-width: 800px) {
    display: none;
  }

  div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    span {
      font-size: 1.5rem;
    }
  }
`;

const TextBold = styled.span`
  font-family: RalewayBold;
  font-size: 1rem;
  width: 100%;
`;

export const query = graphql`
  {
    studio: sanityStudio {
      id
      _rawDescription
      presenters
      script
      showrunner
      director
      order
      video
      title
      slug {
        current
      }
      gallery_image {
        asset {
          id
        }
      }
    }
  }
`;
